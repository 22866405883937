import axios from 'axios'

// create an axios instance
const service = axios.create({
  // 本地
  // baseURL: 'http://localhost:3000/',
  // 测试地址
  baseURL: 'https://www.dev-registration.elcompanies.com/',
  // 生产地址
  // baseURL: 'https://www.registration.elcompanies.com/',
  timeout: 300000 // request timeout

})
 
// request interceptor
service.interceptors.request.use(
  config => {
    config.headers['token'] = localStorage.getItem('token')
    return config
  },
  error => {
    // do something with request errorx

    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const status = response.status
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (status !== 200) {
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  async error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
