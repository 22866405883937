import request from '@/utils/request'

export function generateOtp(data) {
    return request({
        url: '/api/sms/generate',
        method: 'post',
        params: data
    })
}

export function VerifyOtp(data) {
    return request({
        url: '/api/sms/vertify',
        method: 'post',
        params: data
    })
}

export function relateSearch(data){
    return request({
        url: '/api/user/searchCustomer',
        method: 'post',
        params: data
    })
}

export function relateAddOrUpdate(data){
    return request({
        url: '/api/user/addOrUpdate',
        method: 'post',
        data
    })
}

export function searchStoreName(data){
    return request({
        url: '/api/user/searchStore',
        method: 'post',
        params: data
    })
}

export function getToken(data){
    return request({
        url: '/api/user/getToken',
        method: 'post',
        data
    })
}

export function getSession(data){
    return request({
        url: '/api/session/getSession',
        method: 'post',
        data
    })
}